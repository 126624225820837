document.addEventListener("DOMContentLoaded", (event) => {
  const cookieBlock = document.querySelector('.cookie');
  const cookieButton = cookieBlock?.querySelector('button');

  cookieButton.addEventListener('click', () => {
    localStorage.setItem('acceptCookie', true);
    cookieBlock?.remove();
  });

  if (localStorage.getItem('acceptCookie')) cookieBlock?.remove();
});
